<div class="footer ">
	<div class="upper-footer ">
		<div class="linking-wrapper body-container">
			<div class="linking-section">
				<div class="company-part fs-16 fw-500">
					<img (click)="goToHomepage()" alt="Footer logo" src="./assets/svg/conkart-logo.svg"
						class="footer-logo pointer" alt=" company-logo">
					<p class="pt-10">
						Conkart: India's premier B2B marketplace for construction materials. Connect with verified
						sellers & customers across
						India for the best deals. Better call Conkart!
					</p>

					<p class="pt-5" *ngIf="!getPlatformService.isDesktop">
						<a class="support-mail" href="mailto:support@conkart.com"> support@conkart.com</a>
					</p>
				</div>
				<div class="social-option">
					<a href="https://www.facebook.com/conkart.in?mibextid=LQQJ4d" target="_blank"><img alt="Facebook"
							src="assets/svg/fb.svg" alt=""></a>
					<a href="https://www.linkedin.com/company/conkart/" target="_blank"><img alt="Linkedin"
							class="ml-25" src="assets/svg/in.svg" alt=""></a>
					<a href="https://www.instagram.com/conkartinfra?igsh=bjd3YjNqNHN3MmFj" target="_blank"><img
							alt="Instagram" class="ml-25" src="assets/svg/insta.svg" alt=""></a>
				</div>
			</div>
			<div class="mid-sec">
				<div class="linking-section middle fs-16 ">
					<div class="heading fs-16 fw-b">Company</div>
					<ul class="fs-16 fw-500">
						<li routerLinkActive (click)="footLinkNavigations('about_us')">About us</li>
						<li routerLinkActive (click)="footLinkNavigations('contact_us')">Contact us</li>
						<li routerLinkActive (click)="footLinkNavigations('privacy_policy')">Privacy policy</li>
						<li routerLinkActive (click)="footLinkNavigations('terms_of_use')">Terms of use</li>

					</ul>
				</div>
				<div class="linking-section middle fs-16">
					<div class="heading fs-16 fw-b"></div>
					<ul class="fs-16 fw-500">
						<li routerLinkActive (click)="footLinkNavigations('faq')">FAQs</li>
						<li routerLinkActive (click)="footLinkNavigations('newsletter')">Newsletters</li>
						<li routerLinkActive (click)="footLinkNavigations('blog')">Blog</li>

					</ul>
				</div>
			</div>
			<div class="right-sec">
				<div class="linking-section">
					<div class="heading fs-16 fw-b">Sign up for newsletters</div>
					<div class="newsletter-email-container">
						<div>
							<ion-input class="w-280" placeholder="Enter email" (ionChange)="email($event)"
								[(ngModel)]="emailInputValue" autocomplete="new-email" [maxlength]="50"
								(ionClear)="onEmailClear()" #emailInput>
							</ion-input>
							<ion-text color="danger mt-5" *ngIf="!isEmailValid"> Incorrect email address </ion-text>
						</div>
						<button class="submit-btn w-182 mt-30" [disabled]="buttonDisabled" (click)="onSubscribe()">
							<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
							Subscribe
						</button>
					</div>
				</div>
				<div class="arrowBtn">
					<button color="primary-contrast" (click)="topScroll()">
						<img alt="Arrow Up" src="./assets/svg/arrow_up.svg">
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="lower-footer fs-16 fw-500">
		<div class="body-container m-auto">
			Copyright © {{currentYear}} Conkart
		</div>
	</div>
</div>