import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
	{
		path: 'login',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'registration',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'forgot-password',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'create-new-password',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'two-factor-authentication',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'seller-register',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'registration-type',
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'cart-page',
		loadChildren: () => import('./pages/cart-page/cart-page.module').then(m => m.CartPagePageModule)
	},
	{
		path: 'seller-register',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: 'registration-type',
		loadChildren: () => import('./pages/registration-page/registration-page.module').then(m => m.RegistrationPagePageModule)
	},
	{
		path: '',
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'tabs',
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'blog',
		loadChildren: () => import('./pages/blogs/blogs.module').then(m => m.BlogsPageModule)
	},
	{
		path: 'add-blog/:id',
		loadChildren: () => import('./components/add-blog/add-blog.module').then(m => m.AddBlogModule)
	},
	{
		path: 'newsletter',
		loadChildren: () => import('./pages/newsletters/newsletters.module').then(m => m.NewslettersPageModule)
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./components/contact-us/contact-us.module').then(m => m.ContactUsModule)
	},
	{
		path: 'faq',
		loadChildren: () => import('./components/faq/faq.module').then(m => m.FaqModule)
	},

	{
		path: 'privacy-policy',
		loadChildren: () => import('./components/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
	},
	{
		path: 'about-us',
		loadChildren: () => import('./components/about-us/about-us.module').then(m => m.AboutUsModule)
	},
	{
		path: 'add-blog',
		loadChildren: () => import('./components/add-blog/add-blog.module').then(m => m.AddBlogModule)
	},
	{
		path: 'terms-of-use',
		loadChildren: () => import('./components/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
	},
	{
		path: 'product-search',
		loadChildren: () => import('./components/product-search/product-search.module').then(m => m.ProductSearchModule)
	},
	{
		path: "landing",
		loadChildren: () => import('./pages/landing-screen/landing-screen.module').then(m => m.LandingScreenPageModule)
	},
	{
		path: '**',
		component: NotFoundComponent
	},



];


@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking', })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
