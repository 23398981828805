import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {

    public subscription = new Subscription();
    public isLoading = false;
    public aboutUsData: any;
    @Input() isModal: boolean = false;

    constructor(
        private dataService: ApiService,
        private toasterService: ToasterService,
        public commonService: CommonService,
        private modalService: ModalService,
    ) { }

    ngOnInit() {

        this.getAboutUs();
    }

    onCancel() {
        this.modalService.dismissModal();
    }


    getAboutUs() {
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getTermsOfUse).subscribe(data => {
                setTimeout(() => {
                    this.isLoading = false;
                }, 300)
                if (data['code'] == 200 && data['data']) {
                    this.aboutUsData = data['data']['description'];
                }
                else {
                    this.toasterService.errorToast(data['message']);
                }
            }, error => {
                this.isLoading = false;
                this.toasterService.errorToast(error['message']);

            })
        )
    }


}
