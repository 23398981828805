<div class="add-address p-50 autoComplete">
	<div class="address-container">
		<form [formGroup]="addEditAddressForm">
			<div class="address-info">
				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Location Tag <span
								[ngClass]="isSubmitted && addEditAddressForm.get('location_tag').errors?.required ? 'error-msg' : ''">*</span></ion-label>
						<ng-select class="select-input mt-5" placeholder="Select Location Tag" [items]="locationTag"
							bindLabel="name" bindValue="id" formControlName="location_tag" [clearable]="false"
							[searchable]="false">
							<ng-option *ngFor="let option of locationTag" [value]="option.address_type_id"
								[attr.disabled]="option.disabled">
								{{ option.address_type }}
							</ng-option>
						</ng-select>
						<ng-container *ngFor="let validation of validation_messages.location_tag">
							<ng-container
								*ngIf="addEditAddressForm.get('location_tag').hasError(validation.type) && addEditAddressForm.get('location_tag').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Custom Tags</ion-label>

						<ion-input placeholder="Enter Custom Tag" capitalizeFirst
							formControlName="company_tag"></ion-input>

						<ng-container *ngFor="let validation of validation_messages.company_tag">
							<ng-container
								*ngIf="addEditAddressForm.get('company_tag').hasError(validation.type) && addEditAddressForm.get('company_tag').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>
				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Name <span
								[ngClass]="isSubmitted && addEditAddressForm.get('name').errors?.required ? 'error-msg' : ''">*</span></ion-label>

						<ion-input placeholder="Enter Name" capitalizeFirst formControlName="name"></ion-input>

						<ng-container *ngFor="let validation of validation_messages.name">
							<ng-container
								*ngIf="addEditAddressForm.get('name').hasError(validation.type) && addEditAddressForm.get('name').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<div class="mapOption" [ngClass]="isAutoSelected ? 'mb-10' : ''">
							<ion-label>Address <span
									[ngClass]="isSubmitted && addEditAddressForm.get('address').errors?.required ? 'error-msg' : ''">*</span></ion-label>
							<ion-input placeholder="Enter Address" (keypress)="addressChange()"
								(ionInput)="addressChange()" (ionChange)="getPlaceAutocomplete($event)" capitalizeFirst
								formControlName="address"></ion-input>
							<ion-text color="danger" class="absolute" *ngIf="isAutoSelected">
								Please add
								valid address </ion-text>

							<ion-list class="autoAddress" *ngIf="autocompleteItems.length > 0" class="autocomplete">
								<div class="items" *ngFor="let item of autocompleteItems"
									(click)="selectSearchResult(item,addEditAddressForm.controls.address)">{{
									item.description }}</div>
							</ion-list>

							<ng-container *ngFor="let validation of validation_messages.address">
								<ng-container
									*ngIf="addEditAddressForm.get('address').hasError(validation.type) && addEditAddressForm.get('address').dirty && !isAutoSelected">
									<ion-text color="danger" class="error-msg">
										{{validation.message }}
									</ion-text>
								</ng-container>
							</ng-container>
						</div>
					</ion-col>
				</ion-row>
				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Country <span
								[ngClass]="isSubmitted && addEditAddressForm.get('country').errors?.required ? 'error-msg' : ''">*</span></ion-label>
						<ng-select class="select-input mt-5" placeholder="Select Country" [items]="countryList"
							bindLabel="country" bindValue="country_id" formControlName="country" [clearable]="false"
							[searchable]="false" (change)="countryChange($event)">
						</ng-select>
						<ng-container *ngFor="let validation of validation_messages.country">
							<ng-container
								*ngIf="addEditAddressForm.get('country').hasError(validation.type) && addEditAddressForm.get('country').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>State <span
								[ngClass]="isSubmitted && addEditAddressForm.get('state').errors?.required ? 'error-msg' : ''">*</span></ion-label>
						<ng-select placeholder="Select State" [loading]="isStateLoading" class="select-input mt-5"
							[items]="stateList" bindLabel="state" bindValue="state_id" [searchable]="false"
							formControlName="state" [clearable]="false" (change)="stateChange($event)">
						</ng-select>
						<ng-container *ngFor="let validation of validation_messages.state">
							<ng-container
								*ngIf="addEditAddressForm.get('state').hasError(validation.type) && addEditAddressForm.get('state').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>
				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label class="mb-5">District <span
								[ngClass]="isSubmitted && addEditAddressForm.get('district').errors?.required ? 'error-msg' : ''">*</span></ion-label>
						<ng-select placeholder="Select District" [loading]="isDistrictLoading" class="select-input mt-5"
							[items]="districtList" bindLabel="district" bindValue="district_id" [searchable]="false"
							formControlName="district" [clearable]="false" (change)="districtChange($event)">
						</ng-select>
						<ng-container *ngFor="let validation of validation_messages.district">
							<ng-container
								*ngIf="addEditAddressForm.get('district').hasError(validation.type) && addEditAddressForm.get('district').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label class="mb-5">Taluka <span
								[ngClass]="isSubmitted && addEditAddressForm.get('taluka').errors?.required ? 'error-msg' : ''">*</span></ion-label>
						<ng-select placeholder="Select Taluka" [loading]="isTalukaLoading" class="select-input mt-5"
							[items]="talukaList" bindLabel="taluka" bindValue="taluka_id" formControlName="taluka"
							[clearOnBackspace]="talukaList && talukaList.length ? false : true"
							[clearable]="talukaList && talukaList.length ? false : true"
							[addTag]="talukaList && talukaList.length ? false : true" [addTagText]="'Select'"
							(change)="talukaChange($event)"
							[editableSearchTerm]="talukaList && talukaList.length ? false : true"
							[searchable]="talukaList && talukaList.length ? false : true">
						</ng-select>
					</ion-col>
				</ion-row>
				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Mobile number <span
								[ngClass]="isSubmitted && addEditAddressForm.get('mobile_no').errors?.required ? 'error-msg' : ''">*</span></ion-label>

						<ion-input type="tel" (keypress)="phoneClick($event)" placeholder="Enter Mobile Number"
							inputmode="numeric" formControlName="mobile_no" maxlength="10">
							<label for="" class="country-code ml-5 mr-10">
								+91 <img class="pl-5" alt="country extension" src="./assets/svg/arrowdown.svg" priority>
							</label>
						</ion-input>
						<ng-container *ngFor="let validation of validation_messages.mobile_no">
							<ng-container
								*ngIf="addEditAddressForm.get('mobile_no').hasError(validation.type) && addEditAddressForm.get('mobile_no').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Zipcode <span
								[ngClass]="isSubmitted && addEditAddressForm.get('zipcode').errors?.required ? 'error-msg' : ''">*</span></ion-label>

						<ion-input capitalizeFirst formControlName="zipcode" minlength="6" maxlength="6"
							(keypress)="numberOnlyValidation($event)" placeholder="Enter Zipcode"></ion-input>

						<ng-container *ngFor="let validation of validation_messages.zipcode">
							<ng-container
								*ngIf="addEditAddressForm.get('zipcode').hasError(validation.type) && addEditAddressForm.get('zipcode').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>
				<ion-row class="mb-5">
					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" class="pr-25">
						<ion-label>GST Number <span
								[ngClass]="addEditAddressForm.get('gst').hasError('required') && addEditAddressForm.get('gst').dirty ? 'error-msg' : ''">*</span></ion-label>
						<ion-input capitalizeFirst formControlName="gst" placeholder="Enter GST Number"
							(keypress)="restrictSpecialChar($event)" minlength="15" maxlength="15">
						</ion-input>
						<ng-container *ngFor="let validation of validation_messages.gst">
							<ng-container
								*ngIf="addEditAddressForm.get('gst').hasError(validation.type) && addEditAddressForm.get('gst').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message}}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" class="center-checkbox">
						<div class="name check-box-container fs-16 fw-500">
							<ion-checkbox slot="start" [(ngModel)]="is_default_billing"
								[ngModelOptions]="{standalone: true}"></ion-checkbox>
							<ion-label class="ml-10 label-cursor" (click)="toggleCheckbox('billing')">Default billing
								address</ion-label>
						</div>

						<div class="name check-box-container fs-16 fw-500">
							<ion-checkbox slot="start" [(ngModel)]="is_default_shipping"
								[ngModelOptions]="{standalone: true}"></ion-checkbox>
							<ion-label class="ml-10 label-cursor" (click)="toggleCheckbox('shipping')">Default shipping
								address</ion-label>
						</div>
					</ion-col>

				</ion-row>

			</div>
		</form>
		<div class="form-container form-button mt-30 ml-5">
			<button [disabled]="isLoading" (click)="addAddress()" type="primary" class="mr-20 w-156 submit-btn">
				<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
				<span *ngIf="!addressId">Save</span>
				<span *ngIf="addressId">Update</span>
			</button>
			<button (click)="cancel()" class="clear-btn w-156">Cancel</button>

		</div>

	</div>
</div>