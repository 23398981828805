<div class="add-company-address autoComplete" [ngClass]="fromProfile ? 'border-none p-20':'p-50 '">
	<h2 class="mt-0 mb-20 fw-600" *ngIf="!fromProfile">{{dataId && !isView ? 'Edit Vehicle': isView ? 'View
		Vehicle':'Add Vehicle'}}</h2>
	<div class="address-container" *ngIf="isEditLoading || isLoading">
		<app-loader></app-loader>
	</div>
	<div *ngIf="isView">
		<div class="vehicle-div ml-5 mb-15">
			<img [src]="vehicleImg ? vehicleImg : './assets/svg/truck.svg'" alt="Conkart Vehicle" class="vehicle-img" />
		</div>
	</div>
	<div class="address-container" *ngIf="!isEditLoading && !isLoading && addEditVehicleForm">
		<form [formGroup]="addEditVehicleForm" (keydown.enter)="preventSubmit($event)">
			<div class="address-info">
				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Vehicle Name *</ion-label>
						<ion-input capitalizeFirst placeholder="Enter Vehicle Name" formControlName="vehicle_name"
							[readonly]="isView"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.vehicle_name">
							<ng-container *ngIf="addEditVehicleForm.get('vehicle_name').hasError(validation.type) &&
                                addEditVehicleForm.get('vehicle_name').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Vehicle Type *</ion-label>
						<ng-select class="select-input mt-5" placeholder="Select Vehicle Type"
							(change)="vehicleTypeChange($event)" [items]="vehicleType" bindLabel="type_name"
							bindValue="vehicle_type_id" formControlName="vehicle_type" [clearable]="false"
							[readonly]="isView">
						</ng-select>

						<ng-container *ngFor="let validation of validation_messages.vehicle_type">
							<ng-container
								*ngIf="addEditVehicleForm.get('vehicle_type').hasError(validation.type) && addEditVehicleForm.get('vehicle_type').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>

				<ion-row class="mb-5">
					<ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
						<ion-label>Description</ion-label>
						<ion-input capitalizeFirst placeholder="Description" formControlName="description"
							[readonly]="isView"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.vehicle_name">
							<ng-container *ngIf="addEditVehicleForm.get('description').hasError(validation.type) &&
                                                    addEditVehicleForm.get('description').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>
				</ion-row>

				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Category*</ion-label>
						<ng-select class="select-input mt-5" placeholder="Select Category"
							(change)="categoryChange($event)" [items]="categoryList" bindLabel="category_name"
							bindValue="category_id" formControlName="category" [clearable]="false" [multiple]="true"
							[readonly]="true">
						</ng-select>
						<ng-container *ngFor="let validation of validation_messages.category">
							<ng-container *ngIf="addEditVehicleForm.get('category').hasError(validation.type) &&
                                                addEditVehicleForm.get('category').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Unit *</ion-label>
						<ng-select class="select-input mt-5" placeholder="Select Unit" (change)="unitChange($event)"
							[items]="unitList" bindLabel="unit_name" bindValue="unit_id" formControlName="unit"
							[clearable]="false">
						</ng-select>

						<ng-container *ngFor="let validation of validation_messages.unit">
							<ng-container
								*ngIf="addEditVehicleForm.get('unit').hasError(validation.type) && addEditVehicleForm.get('unit').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>

				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Minimum Capacity* {{unitList && unitList.length > 0 ? '(' + unitList[0].unit_name +
							')' : null}}</ion-label>
						<ion-input placeholder="Enter Minimum Capacity" formControlName="minimum_capacity"
							[readonly]="isView" minlength="1" maxlength="9"
							(keypress)="numberOnlyValidation($event)"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.minimum_capacity">
							<ng-container *ngIf="addEditVehicleForm.get('minimum_capacity').hasError(validation.type) &&
                                                                addEditVehicleForm.get('minimum_capacity').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Maximum Capacity* {{unitList && unitList.length > 0 ? '(' + unitList[0].unit_name +
							')' : null}}</ion-label>
						<ion-input placeholder="Enter Maximum Capacity" formControlName="maximum_capacity"
							[readonly]="isView" minlength="1" maxlength="9"
							(keypress)="numberOnlyValidation($event)"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.maximum_capacity">
							<ng-container
								*ngIf="addEditVehicleForm.get('maximum_capacity').hasError(validation.type) && addEditVehicleForm.get('maximum_capacity').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>

				<ion-row class="mb-5">
					<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Standard Per KM Price (INR)* (Including GST)</ion-label>
						<ion-input placeholder="Enter Standard Price" formControlName="standard_price"
							[readonly]="isView"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.standard_price">
							<ng-container
								*ngIf="addEditVehicleForm.get('standard_price').hasError(validation.type) &&
                                                                                    addEditVehicleForm.get('standard_price').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>
					</ion-col>

					<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
						<ion-label>Registration Plate Number</ion-label>
						<ion-input placeholder="Enter Registration Plate Number" formControlName="vehicle_no"
							[readonly]="isView"></ion-input>
						<ng-container *ngFor="let validation of validation_messages.vehicle_no">
							<ng-container
								*ngIf="addEditVehicleForm.get('vehicle_no').hasError(validation.type) && addEditVehicleForm.get('vehicle_no').dirty">
								<ion-text color="danger" class="error-msg">
									{{validation.message }}
								</ion-text>
							</ng-container>
						</ng-container>

					</ion-col>
				</ion-row>

				<div class="mv-20" *ngIf="!isView">
					<div class="vehicle-div ml-5">
						<img [src]="vehicleImg ? vehicleImg : './assets/svg/truck.svg'" alt="Conkart Vehicle"
							class="vehicle-img" />
						<button [disabled]="isLoading && isDisabled" (click)="callInput();"
							class="mt-10 w-156 vehicle-btn">
							<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
							Upload
						</button>

						<input [disabled]="isDisabled" #file type="file" id="fileInput" (click)="file.value = null"
							(change)="fileChangeEvent($event)" hidden />
					</div>
				</div>
			</div>
		</form>



		<div class="mh-5" *ngIf="!isView && !isDisabled">
			<ng-container *ngFor="let data of selectedRegionList;let i = index">

				<ion-row class="align-center space-between expand-div p-15 mv-10" *ngIf="!data.isRegionOpen">
					<p class="m-0 fw-700">
						{{data.region}}
					</p>
					<img alt="Expand" (click)="openRegion(data,i)" class="close-icon mr-10"
						src="../../../../assets/icon/add-dark.svg">
				</ion-row>

				<div *ngIf="data.isRegionOpen" class="border-div p-15 mt-15">

					<ion-row class="mb-5">
						<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
							<ion-label>Region*</ion-label>
							<ng-select class="select-input mt-5" placeholder="Select Region" [items]="regionList"
								bindLabel="district" bindValue="district_id" [clearable]="false"
								[(ngModel)]="data.region_id" (change)="regionChange(data)">
							</ng-select>
						</ion-col>

						<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
							class="display-flex space-between">
							<div *ngIf="data.is_region_level" class="width-100">
								<ion-label>Price/KM (INR)(Including GST)<span
										*ngIf="data.is_region_level">*</span></ion-label>
								<ion-input placeholder="Price/KM (INR)"
									[disabled]="data.is_region_level == false ? true:false"
									[(ngModel)]="data.per_km_price" (change)="regionPriceChange(data)" maxlength="9"
									type="text" (ionInput)="quantityInput($event,data)"
									(ionBlur)="quantityInputBlur($event,data)"
									(keypress)="isnumeric($event)"></ion-input>
							</div>


							<div class="flex-dir-col d-flex align-end width-100">
								<img alt="Collapse" (click)="openRegion(data,i)" class="close-icon mr-3 cursor-pointer"
									src="../../../../assets/icon/minus-dark.svg">

								<img (click)="deleteRegion(data,i)" src="/assets/icon/delete.svg"
									class="cursor-pointer mt-15" alt="Delete Region" title="Delete Region" />
							</div>
						</ion-col>



					</ion-row>

					<div class="ml-5">
						<div class="align-center mt-10 mb-20">
							<span class="toggle-span">
								<ion-toggle (click)="isDiffToggle(data,i)"
									[(ngModel)]="data.is_region_level"></ion-toggle>
							</span>
							<span class="ml-10">Same Price For Different Taluka </span>
						</div>
					</div>

					<div *ngIf="!data.is_region_level" class="ml-5">
						<ion-row>
							<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
								<ion-label>Taluka*</ion-label>
								<ng-select class="select-input mt-5" placeholder="Select Taluka"
									[items]="data.talukaList" bindLabel="taluka" bindValue="taluka_id"
									[(ngModel)]="data.selectedTalukaListId" [clearable]="false"
									(change)="selectedTalukaChange($event,data)" [multiple]="true">
								</ng-select>
							</ion-col>
						</ion-row>


						<ng-container *ngIf="data.selectedTalukaList && data.selectedTalukaList.length > 0">
							<div class=" fw-600 mt-20 flex space-between width-60p">
								<div class="align-center width-60p">
									Taluka
								</div>

								<div class="width-60p">
									Price/KM (INR)*(Including GST)
								</div>
							</div>

							<div *ngFor="let oneArea of data.selectedTalukaList;let i = index"
								class=" fw-500 mv-7 flex space-between width-60p">
								<div class="align-center width-60p">
									<p>{{oneArea.taluka}}</p>
								</div>

								<div class="align-center width-60p">
									<div>
										<ion-input pattern="[0-9]*" appBlockCopyPaste inputmode="numeric"
											class="width-200" (change)="priceChangeValidation(data)" maxlength="9"
											type="text" (ionInput)="quantityInput($event,oneArea)"
											(ionBlur)="quantityInputBlur($event,oneArea)" (keypress)="isnumeric($event)"
											placeholder="00" [ngModelOptions]="{standalone: true}"
											[(ngModel)]="oneArea.per_km_price" appTwoDigitDecimalNumber></ion-input>

										<div class="error-text" *ngIf="oneArea.isPriceValid">Please add Price
										</div>
										<div class="error-text" *ngIf="oneArea.isNegativePrice">Please add valid
											Price
										</div>
									</div>
								</div>


							</div>
						</ng-container>


					</div>
				</div>


			</ng-container>

			<button *ngIf="!isDisabled" [disabled]="isLoading" (click)="addRegion()" type="primary"
				class="mt-20 mr-20 w-156 submit-btn">
				<ion-spinner class="spinner-btn" name="lines-sharp-small"
					*ngIf="isLoading && !isView && !isDisabled"></ion-spinner>
				Add Region
			</button>
		</div>

		<div class="mh-5" *ngIf="isView || isDisabled">
			<ng-container *ngFor="let data of selectedRegionList">
				<div class="border-div expand-div p-15 mt-15">

					<ion-row class="mb-5">
						<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
							<ion-label>Region*</ion-label>
							<ng-select class="select-input mt-5" placeholder="Select Region" [items]="regionList"
								bindLabel="district" bindValue="district_id" [clearable]="false"
								[(ngModel)]="data.region_id" (change)="regionChange(data)" [readonly]="true">
							</ng-select>
						</ion-col>

						<ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
							class="display-flex space-between" *ngIf="data.is_region_level">
							<div>
								<ion-label>Price/KM (INR)*(Including GST)</ion-label>
								<ion-input placeholder="Price/KM (INR)" [(ngModel)]="data.per_km_price"
									[readonly]="isView" [disabled]="isDisabled" (change)="regionPriceChange(oneArea)"
									maxlength="9" type="text" (ionInput)="quantityInput($event,oneArea)"
									(ionBlur)="quantityInputBlur($event,oneArea)"
									(keypress)="isnumeric($event)"></ion-input>
							</div>
						</ion-col>


					</ion-row>


					<div *ngIf="data.selectedTalukaList && data.selectedTalukaList.length > 0" class="ml-5">
						<ion-row>
							<ion-col class="pr-25" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
								<ion-label>Taluka*</ion-label>
								<ng-select class="select-input mt-5" placeholder="Select Taluka"
									[items]="data.talukaList" bindLabel="taluka" bindValue="taluka_id"
									[ngModel]="data.selectedTalukaListId" [clearable]="false"
									(change)="selectedTalukaChange($event,data)" [multiple]="true" [readonly]="true">
								</ng-select>
							</ion-col>
						</ion-row>


						<ng-container *ngIf="data.selectedTalukaList && data.selectedTalukaList.length > 0">
							<div class="grey-box-row fw-600 width-60p mt-20">
								<div class="align-center">
									Taluka
								</div>

								<div>
									Price/KM (INR)*(Including GST)
								</div>
							</div>

							<div *ngFor="let oneArea of data.selectedTalukaList;let i = index" class="width-70p">
								<div class="grey-box-row fw-500 mv-7">
									<div class="align-center">
										<p class="mr-10">{{oneArea.taluka}}</p>
									</div>

									<div class="align-center">
										<div>
											<ion-input pattern="[0-9]*" appBlockCopyPaste inputmode="numeric"
												class="width-200" (change)="priceValidation(oneArea)" maxlength="9"
												type="text" (ionInput)="quantityInput($event,oneArea)"
												(ionBlur)="quantityInputBlur($event,oneArea)"
												(keypress)="isnumeric($event)" placeholder="00" [readonly]="isView"
												[disabled]="isDisabled" [ngModelOptions]="{standalone: true}"
												[(ngModel)]="oneArea.per_km_price" appTwoDigitDecimalNumber></ion-input>

											<div class="error-text" *ngIf="oneArea.isPriceValid">Please add Price
											</div>
											<div class="error-text" *ngIf="oneArea.isNegativePrice">Please add valid
												Price
											</div>
										</div>

									</div>


								</div>
							</div>
						</ng-container>


					</div>
				</div>


			</ng-container>
		</div>


		<div class="form-container form-button mt-30" *ngIf="!isView && !isDisabled">
			<button [disabled]="isLoading" (click)="addVehicle()" type="primary" class="mr-20 w-156 submit-btn">
				<ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
				{{dataId? 'Update' : 'Save'}}
			</button>
			<button (click)="cancel()" class="clear-btn w-156">Cancel</button>
		</div>

		<div class="form-container form-button mt-30" *ngIf="isView && !isDisabled">
			<button (click)="cancel()" class="clear-btn w-156">Back</button>
		</div>
	</div>
</div>