import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { ToasterService } from './../../services/toaster.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	currentYear = new Date().getFullYear();
	@ViewChild('emailInput', { static: false }) emailInput: IonInput;
	public subscription = new Subscription();
	public emailInputValue = "";
	public isEmailValid = true;
	public buttonDisabled = true;
	public isLoading = false;

	constructor(private toasterService: ToasterService,
		private dataService: ApiService,
		public commonService: CommonService,
		private navCtrl: NavController,
		public getPlatformService: GetPlatformService
	) { }

	ngOnInit() {
		this.emailInputValue = "";
	}

	ionViewWillEnter() {
		this.emailInputValue = "";
	}

	email(event) {
		const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
		if (this.emailInputValue && !EMAIL_REGEXP.test(this.emailInputValue.trim())) {
			this.isEmailValid = false;
			this.buttonDisabled = true;
		} else {
			this.isEmailValid = true;
			this.buttonDisabled = false;
		}
		let emailValue = event.target.value;
		if (!emailValue) {
			this.buttonDisabled = true;
		}
	}

	onEmailClear() {
		this.emailInputValue = "";
		setTimeout(() => {
			this.buttonDisabled = true;
		}, 200);
	}

	footLinkNavigations(data) {
		switch (data) {

			case 'blog':
				this.navCtrl.navigateForward('/blog', { animated: false });
				break;

			case 'faq':
				this.navCtrl.navigateForward('/faq', { animated: false });
				break;

			case 'privacy_policy':
				this.navCtrl.navigateForward('/privacy-policy', { animated: false });
				break;

			case 'about_us':
				this.navCtrl.navigateForward('/about-us', { animated: false });
				break;

			case 'contact_us':
				this.navCtrl.navigateForward('/contact-us', { animated: false });
				break;

			case 'newsletter':
				this.navCtrl.navigateForward('/newsletter', { animated: false });
				break;

			case 'terms_of_use':
				this.navCtrl.navigateForward('/terms-of-use', { animated: false });
				break;

		}
	}

	onSubscribe() {
		const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
		if (this.emailInputValue && !EMAIL_REGEXP.test(this.emailInputValue.trim())) {
			return;
		}
		if (this.emailInputValue == "") {
			return;
		}
		const params = {
			"email": this.emailInputValue
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.newsLetter, params).subscribe(data => {
				if (data['code'] == 200) {
					if (data['data']) {
						this.toasterService.successToast(data['message']);
						this.emailInputValue = "";
						setTimeout(() => {
							this.buttonDisabled = true;
						}, 500);
					}
					this.isLoading = false;
				}
				else {
					this.toasterService.errorToast(data['message']);
					this.emailInputValue = "";
					setTimeout(() => {
						this.buttonDisabled = true;
					}, 500);
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				setTimeout(() => {
					this.buttonDisabled = true;
				}, 500);
				this.emailInputValue = "";
				this.toasterService.errorToast(error['message']);

			})
		)
	}

	topScroll() {
		this.commonService.scrollToTop();
	}

	goToHomepage() {
		if (this.commonService.isLoggedIn && this.commonService.userData['isCustomer']) {
			this.navCtrl.navigateRoot('/home', { animated: false });
		}
		if (this.commonService.isLoggedIn && this.commonService.userData['isSeller']) {
			this.navCtrl.navigateRoot('/home/account-details/dashboard', { animated: false });
		}
		if (!this.commonService.isLoggedIn) {
			this.navCtrl.navigateRoot('/home', { animated: false });
		}
	}


}
